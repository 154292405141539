import React from 'react';
import IntegrationForm from '../Form';
import { render } from '../../../newRender';

describe('IntegrationForm', () => {
  it('should render form correctly', () => {
    const rules = {
      time: 'required',
      configuration: 'required',
    };
    const renderForm = render(<IntegrationForm rules={rules} />);
    expect(renderForm).toBeDefined();
  });
});
