import { entityTypes } from '../entityTypesNode';

describe('entityTypes', () => {
  it('should return Client text if organization type is 1', () => {
    const organization = {
      type: '1',
    };
    const results = entityTypes(organization.type);

    expect(results).toStrictEqual({
      STUDENT: {
        name: 'Client',
        color: 'grey',
      },
      STAFF: {
        name: 'Staff',
        color: 'brown',
      },
      ADMIN: {
        name: 'Admin',
        color: 'black',
      },
    },);
  });
});
