import { calculateInstallments } from '../helpers/calculateInstallmentsForm';

describe('calculateInstallments', () => {
  const amount = 100;
  const allowedInstallments = 3;

  const normalizeSpaces = (str) => str.replace(/\u00A0/g, ' ');

  it('should return an array of installments', () => {
    const expected = [
      {
        text: '1 x R$ 100,00',
        value: 1,
        key: 0,
      },
      {
        text: '2 x R$ 50,00',
        value: 2,
        key: 1,
      },
      {
        text: '3 x R$ 33,33',
        value: 3,
        key: 2,
      },
    ];

    expect(
      calculateInstallments(amount, allowedInstallments).map((item) => ({
        ...item,
        text: normalizeSpaces(item.text),
      })),
    ).toEqual(
      expected.map((item) => ({
        ...item,
        text: normalizeSpaces(item.text),
      })),
    );
  });

  it('should return an empty array if allowedInstallments is 0', () => {
    const expected = [];

    const result = calculateInstallments(amount, 0);
    expect(result).toEqual(expected);
  });
});
