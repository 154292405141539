/**
 * Parameters for the function calculateInstallments
 * @param {number} amount
 * @param {number} allowedInstallments
 * @returns {Array}
 */

export function calculateInstallments(amount, allowedInstallments) {
  if (typeof allowedInstallments !== 'number' || allowedInstallments < 1) {
    return [];
  }

  return [...Array(allowedInstallments).keys()].map((i) => ({
    text: `${i + 1} x ${parseFloat(amount / (i + 1)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
    value: i + 1,
    key: i,
  }));
}
