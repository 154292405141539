import { typeOptions } from '../typeOptionsNode';

describe('typeOptions', () => {
  it('should return Client text if organization type is 1', () => {
    const organization = {
      type: '1',
    };
    const options = typeOptions(organization);
    expect(options).toEqual([
      {
        key: 0,
        text: 'Client',
        value: 'STUDENT',
        'data-value': 'student',
      },
      { key: 1, text: 'Staff', value: 'STAFF', 'data-value': 'staff' },
      { key: 2, text: 'Admin', value: 'ADMIN', 'data-value': 'admin' },
    ]);
  });
});
