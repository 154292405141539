/**
 * @param {{
 *   validTaxId: boolean,
 *   currentTaxIdLength: number,
 *   canSign: boolean,
 *   signer: any,
 *   edit: boolean
 * }} param
 */
export function getCpfStatus({
  validTaxId,
  currentTaxIdLength,
  canSign,
  signer,
  edit,
}) {
  if (edit) return 'DISABLED';
  if (canSign) {
    if (currentTaxIdLength > 0 && currentTaxIdLength < 11) return 'INCOMPLETE';
    if (currentTaxIdLength === 0) return 'EMPTY';
    if (!validTaxId) return 'INVALID_VALUE';
    return 'VALID_VALUE';
  }
  if (signer) return 'SIGNED';
  return 'DISABLED';
}
