import React from 'react';
import { Segment, Popup, Icon } from 'semantic-ui-react';
import { uniq } from 'lodash';

import Form from '../../components/Form';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';
import { getTypeOptions } from '../FormField/helpers/getTypeOptionsField';

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  }
};

export default class ReportFieldForm extends Form {

  defaultValues = {
    name: '',
    type: 'SELECT',
    options: [''],
    initial: ''
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    options: [values => this.validateContent('options', values)]
  }

  validateContent = (name, values) => {
    if (name === 'name') return values.trim().length > 0;

    if (name === 'options' && this.state.values.type === 'TEXT') return true;

    if (name === 'options' && (uniq(values).length !== values.length)) throw new Error(__('All fields must be different'));

    const labeledOptions = this.state.values.options.filter(options => options && options.trim().length > 0).length;

    if (name === 'options' && ((this.state.values.options.length !== labeledOptions))) throw new Error(__('Options cannot be empty'));

    if (name === 'options' && this.state.values.options.length === 0 && labeledOptions === 0) throw new Error(__('Reports must have at least one valid option'));
  }

  onTypeChange = (e, data) => {
    const values = this.state.values;

    if (data.value === 'TEXT' || data.value === 'SELECT') {
      values.initial = '';
    } else if (data.value === 'CHECK') {
      values.initial = [];
    }

    if (data.value !== 'TEXT' && values.type === 'TEXT') {
      values.options = [''];
    }

    this.setState({ values },
      () => this.onInputChange(e, data)
    );
  }

  renderPreview = () => {
    const { values } = this.state;

    if (values.type === 'TEXT') {
      return (<Form.TextArea
        rows="5"
        value={values.initial || ''}
      />);
    }

    return values.options.map((option, i) =>
      option ?
        <div key={i}>
          <ColoredCheckbox
            radio={values.type === 'SELECT'}
            label={option}
            checked={values.type === 'CHECK' ? values.initial && values.initial.indexOf(i) > -1 : i === values.initial}
          />
        </div>
      :
        null);
  }

  renderOptions = () =>
    this.state.values.options.map((option, i) =>
      <Form.Input
        key={i}
        placeholder={__('Option')}
        actionPosition="left"
        action={
            (this.state.values.type === 'CHECK') ?
              <Popup
                content={__('Initially checked')}
                trigger={
                  <Segment data-action="toggle-check-initial" data-params={i} compact className="button" style={{ padding: '10px' }} onClick={() => this.toggleValue('initial', i)}>
                    <ColoredCheckbox
                      checked={this.state.values.initial && this.state.values.initial.indexOf(i) > -1}
                    />
                  </Segment>
                }
              />
            :
              <Popup
                content={__('Initially checked')}
                trigger={
                  <Segment
                    data-action="toggle-select-initial" data-params={i} compact className="button" style={{ padding: '10px' }}
                    onClick={this.state.values.initial === i ? () => this.setValue('initial', '') : () => this.setValue('initial', i)}
                  >
                    <ColoredCheckbox
                      radio
                      checked={this.state.values.initial === i}
                    />
                  </Segment>
              }
              />
          }
        icon={{ name: 'times', link: true, 'data-action': 'delete-option', 'data-params': i, onClick: () => this.popValue('options', i) }}
        name="options"
        data-params={i}
        value={option}
        onChange={this.onItemChange(i)}
        autoFocus={this.state.autofocus && this.state.values.options.length === i + 1}
      />
    )

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="ReportFieldForm" {...this.props} onSubmit={this.handleSubmit}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', paddingRight: '32px' }}>
            <p className="bold" style={styles.label}>{__('Name')}</p>
            <Form.Input
              placeholder={__('Name')}
              name="name"
              value={values.name}
              onChange={this.onInputChange}
              autoFocus
            />
            {
              errors && errors.name ?
                <div>
                  <Icon data-value="error" name="exclamation triangle" size="large" color="red" style={{ marginBottom: 8 }} />
                  {__('Name cannot be empty')}
                </div>
                :
                null
            }
            <p className="bold" style={styles.label}>{__('Field type')}</p>
            <Form.Dropdown
              name="type"
              fluid
              selection
              value={values.type}
              options={getTypeOptions()}
              onChange={this.onTypeChange}
            />
            { values.type === 'TEXT' ?
              <div>
                <p className="bold" style={styles.label}>{__('Default text')}</p>
                <Form.TextArea
                  placeholder={__('Initial value (optional)')}
                  name="initial"
                  autoHeight
                  value={values.initial}
                  onChange={(e, { name, value }) => this.onInputChange(e, { name, checked: value })}
                />
              </div>
              :
              <div>
                <p className="bold" style={{ marginBottom: '4px' }}>{__('Options')}</p>
                {this.renderOptions()}
                {
                  errors && errors.options ?
                    <div>
                      <Icon data-value="error" name="exclamation triangle" size="large" color="red" />
                      {errors.options}
                    </div>
                    :
                    null
                }
                <Form.Button
                  data-action="add-option"
                  icon="plus"
                  content={__('Add option')}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ autofocus: true }, () => {
                      if (this.state.values.options.find(option => option === '') === undefined) {
                        this.pushValue('options', '');
                      }
                    });
                  }}
                  type="submit"
                  style={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                />
              </div>

            }
          </div>
          <div className="wordWrapped" style={{ width: '50%', paddingLeft: '32px', display: 'flex', alignItems: 'center' }}>
            <div>
              <div style={{ color: '#a0a0a0', fontWeight: 'bold', marginBottom: '40px' }}><Icon name="eye" />{__('Field Preview')}</div>
              <p className="bold" style={{ fontSize: '16px' }}>{values.name || ' '}</p>
              {this.renderPreview()}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
