import React from 'react';
import { Icon } from 'semantic-ui-react';
import { __ } from '../../../i18n';

export function getTypeOptions() {
  return [
    {
      text: __('Single Select'),
      value: 'SELECT',
      content: (
        <div data-value="SELECT">
          <Icon name="dot circle" style={{ color: '#0080ff' }} />
          {__('Single Select')}
        </div>
      ),
    },
    {
      text: __('Multiple Select'),
      value: 'CHECK',
      content: (
        <div data-value="CHECK">
          <Icon name="check square" style={{ color: '#0080ff' }} />
          {__('Multiple Select')}
        </div>
      ),
    },
    {
      text: __('Text'),
      value: 'TEXT',
      content: (
        <div data-value="TEXT">
          <Icon name="font" style={{ color: '#0080ff' }} />
          {__('Text')}
        </div>
      ),
    },
  ];
}
