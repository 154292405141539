import { __ } from '../../../i18n';
import * as utils from '../../../utils';

export function typeOptions(organization) {
  const organizationType = organization?.type;

  return [
    {
      key: 0,
      text:
        utils.checkIfItIsClientOrSchool(organizationType) === 1
          ? __('Student')
          : __('Client'),
      value: 'STUDENT',
      'data-value': 'student',
    },
    { key: 1, text: __('Staff'), value: 'STAFF', 'data-value': 'staff' },
    { key: 2, text: __('Admin'), value: 'ADMIN', 'data-value': 'admin' },
  ];
}
