import React from 'react';

import Form from '../../components/Form';

import { isNetwork } from '../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { FormContent } from './components/FormContent';

export default class TagForm extends Form {
  defaultValues = {
    name: '',
    checked: false,
    isNetworkTag: true,
  };

  rules = {
    name: ['required', (values) => this.validateContent('name', values)],
  };

  maybes = {
    name: ['required', (values) => this.validateContent('name', values)],
  };

  validateContent = (name, values) => {
    if (name === 'name') {
      return values.trim().length > 0;
    }

    return true;
  };

  render() {
    const { values, errors } = this.state;
    const { organization, edit } = this.props;

    return (
      <Form id="TagForm" {...this.props} onSubmit={this.handleSubmit}>
        <FormContent
          values={values}
          errors={errors}
          organization={organization}
          edit={edit}
          isNetwork={isNetwork(organization)}
          onInputChange={this.onInputChange}
        />
      </Form>
    );
  }
}
