import React from 'react';
import SendInviteModal from '../SendInviteModal';
import { render, screen, fireEvent, waitFor } from '../../../newRender';
describe('SendInviteModal', () => {
  it('should render the component', () => {
    const { container } = render(<SendInviteModal />);
    expect(container).toBeDefined();
  });
  it('OnClick should be called', async () => {
    const handleSubmit = jest.fn();
    render(<SendInviteModal handleSubmit={handleSubmit} />);
    await waitFor(() => screen.getByRole('button', { name: /confirm/i }));
    const confirmButton = screen.getByRole('button', { name: /confirm/i });
    fireEvent.click(confirmButton);
    expect(handleSubmit).toHaveBeenCalled();
  });
});
