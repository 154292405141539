import { __ } from '../../../i18n';
import * as utils from '../../../utils';

export function entityTypes(organizationType) {
  return {
    STUDENT: {
      name:
        utils.checkIfItIsClientOrSchool(organizationType) === 1
          ? __('Student')
          : __('Client'),
      color: 'grey',
    },
    STAFF: { name: __('Staff'), color: 'brown' },
    ADMIN: { name: __('Admin'), color: 'black' },
  };
}
