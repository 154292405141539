import React from 'react';
import GroupItem from '../Item';
import { render } from '../../../newRender';

describe('GroupItem', () => {
  it('should check if the component is rendering correctly', () => {
    const props = {
      group: {
        id: '1',
        name: 'Group 1',
      },
    };
    const container = render(<GroupItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should check if the component is rendering correctly with onClick', () => {
    const props = {
      group: {
        id: '1',
        name: 'Group 1',
      },
      onClick: jest.fn(),
    };
    const container = render(<GroupItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should check if the component is rendering correctly with to', () => {
    const props = {
      group: {
        id: '1',
        name: 'Group 1',
      },
      to: '/group/1',
    };
    const container = render(<GroupItem {...props} />);
    expect(container).toBeDefined();
  });

  it('should render null if no group is provided', () => {
    const { container } = render(<GroupItem />);
    expect(container.firstChild).toBeNull();
  });
});
