import { getCpfStatus } from '../helpers/getCpfStatusHelper';

describe('getCpfStatus', () => {
  it('should return disable if edit is true', () => {
    const edit = true;
    const returnStatus = getCpfStatus({
      validTaxId: true,
      currentTaxIdLength: 0,
      canSign: true,
      signer: true,
      edit,
    });
    expect(returnStatus).toEqual('DISABLED');
  });

  it('should return incomplete if canSign is true and currentTaxIdLength is greater than 0 and less than 11', () => {
    const canSign = true;
    const currentTaxIdLength = 1;
    const returnStatus = getCpfStatus({
      validTaxId: true,
      currentTaxIdLength: currentTaxIdLength,
      canSign,
      signer: true,
      edit: false,
    });
    expect(returnStatus).toEqual('INCOMPLETE');
  });

  it('should return empty if currentTaxIdLength is equal 0', () => {
    const currentTaxIdLength = 0;
    const returnStatus = getCpfStatus({
      validTaxId: true,
      currentTaxIdLength: currentTaxIdLength,
      canSign: true,
      signer: true,
      edit: false,
    });
    expect(returnStatus).toEqual('EMPTY');
  });

  it('should return invalid if validTaxId is false', () => {
    const validTaxId = false;
    const returnStatus = getCpfStatus({
      validTaxId,
      currentTaxIdLength: 11,
      canSign: true,
      signer: true,
      edit: false,
    });
    expect(returnStatus).toEqual('INVALID_VALUE');
  });

  it('should return valid value if canSign is true and validTaxId is true', () => {
    const validTaxId = true;
    const returnStatus = getCpfStatus({
      validTaxId,
      currentTaxIdLength: 11,
      canSign: true,
      signer: true,
      edit: false,
    });
    expect(returnStatus).toEqual('VALID_VALUE');
  });

  it('should return disabled if edit is false and canSign is false and signer is false', () => {
    const signer = false;
    const returnStatus = getCpfStatus({
      validTaxId: true,
      currentTaxIdLength: 11,
      canSign: false,
      signer: signer,
      edit: true,
    });
    expect(returnStatus).toEqual('DISABLED');
  });

  it('should return signed if signer is true', () => {
    const signer = true;
    const returnStatus = getCpfStatus({
      validTaxId: true,
      currentTaxIdLength: 11,
      canSign: false,
      signer: signer,
      edit: false,
    });
    expect(returnStatus).toEqual('SIGNED');
  });
});
