import React from 'react';
import { getTypeOptions } from '../helpers/getTypeOptionsField';
import { Icon } from 'semantic-ui-react';

describe('getTypeoptions', () => {
  it('should return all options', () => {
    const options = getTypeOptions();
    expect(options).toEqual([
      {
        text: 'Single Select',
        value: 'SELECT',
        content: (
          <div data-value="SELECT">
            <Icon name="dot circle" style={{ color: '#0080ff' }} />
            Single Select
          </div>
        ),
      },
      {
        text: 'Multiple Select',
        value: 'CHECK',
        content: (
          <div data-value="CHECK">
            <Icon name="check square" style={{ color: '#0080ff' }} />
            Multiple Select
          </div>
        ),
      },
      {
        text: 'Text',
        value: 'TEXT',
        content: (
          <div data-value="TEXT">
            <Icon name="font" style={{ color: '#0080ff' }} />
            Text
          </div>
        ),
      },
    ]);
  });
});
